.MainContent {
  @include z-index(main);
}

.Pages {
  position: relative;
  opacity: 0;
  // flex-direction: column;
  background-color: $white;
  // flex-grow: 1;
  width: 100%;
  @include media('>=large') {
    width: auto;
    display: flex;
  }
  &:not(.Pages--Home) {
    @include media('>=large') {
      flex-direction: column;
      padding-left: px(74);
    }
    @include media('>=mlarge') {
      padding-left: px(76);
    }
  }
}

// LOADING PAGE
.LoadingPage {
  background-color: $white;
  @include flex-center;
  height: 100vh;
  svg {
    animation: icon-spin 1s infinite linear;
    transform-origin: center center;
    width: pxrem(50);
    height: pxrem(50);
    path {
      fill: $red;
    }
  }
}

@function p() {
  @return percentage(random(99));
}

@keyframes static {
  @for $i from 0 to 99 {
    #{$i}% {
      background-position: p() p();
    }
  }
}

.grain {
  position: fixed;
  width: 100%;
  height: 100%;
  animation: static 2s steps(1) infinite;
  pointer-events: none;
  @include z-index(grain);
  opacity: 0.1;
}

.plantBar {
  --bar-white-color: #fff;
  --bar-black-color: #04030f;
  --bar-color: var(--bar-white-color);
  width: px(14);
  position: fixed;
  left: px(60);
  top: 0;
  height: 100%;
  @include z-index(vsscrollbar);
  display: none;
  transition: opacity 0.3s ease, transform 0.6s ease, background-color 0.3s ease;
  @include media('>=large') {
    display: block;
  }
  @include media('>=mlarge') {
    width: px(16);
  }
  &--hidden {
    opacity: 0;
    transform: scaleX(0.5) translateX(-100%);
  }
  &:after {
    z-index: 2;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: var(--bar-color);
    transition: background-color 0.8s ease;
  }
  &--dark {
    background-color: $dark;
  }
  &--light {
    background-color: $white;
  }
}
.plantBar__inner {
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  position: absolute;
}
.plantBar__inner-bg {
  @include pos100(absolute);
  background-size: 900%;
  background-position: center -100px;
  transition: opacity 0.3s ease;
}
.plantBar__inner-bg--dark {
  opacity: 1;
  background-color: $dark;
}
.plantBar__inner-bg--light {
  opacity: 0;
  background-color: $white;
}
.plantBar.plantBar--light {
  --bar-color: var(--bar-black-color);
  .plantBar__inner-bg--dark {
    opacity: 0;
  }
  .plantBar__inner-bg--light {
    opacity: 1;
  }
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction='horizontal'] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction='horizontal'] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $red;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.modal-video {
  background-color: rgba($black, 0.92) !important;
  &--close {
    opacity: 0 !important;
  }
  &:focus {
    outline: none;
  }
}

.CookieConsent {
  background: $white;
  color: $black;
  position: fixed;
  text-align: center;
  @include flex-center;
  flex-direction: column;
  padding: pxrem(20);
  border: 1px solid $black;
  bottom: 3vw !important;
  right: 6vw;
  width: 45vw;
  min-height: pxrem(140);
  @include z-index(cookie);
  @include media('>=medium') {
    width: pxrem(260);
  }
  @include media('>=large') {
    width: pxrem(300);
  }
  > div {
    font-size: calc(var(--text-sm) * 1.1);
  }
  button {
    white-space: nowrap;
    @include clean;
    margin-top: pxrem(10);
    color: $black;
    padding: pxrem(8) pxrem(16);
    background-color: transparent;
    border: 2px solid $red;
    font-size: calc(var(--text-xs) * 1.1);
    font-weight: 700 !important;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    &:hover {
      background-color: lighten($red, 3%);
      color: $white;
      transition: background-color 0.3s ease;
    }
  }
}

header.Header--mobileOpen {
  --header-color: var(--header-black-color) !important;
  background-color: transparent !important;
}
header.Header--dark {
  background-color: $dark;
}
header.Header--light {
  --header-color: var(--header-black-color);
  background-color: $white;
}

.grecaptcha-badge {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gm-style-cc div {
  background: none !important;
}

*[data-inview-opacity='true'] {
  opacity: 0;
  transition: opacity 1s ease;
  &[data-inview-delay='0.1'] {
    transition: opacity 1.4s ease 0.1s;
  }
  &[data-inview-delay='0.2'] {
    transition: opacity 1.4s ease 0.2s;
  }
  &[data-inview-delay='0.3'] {
    transition: opacity 1.4s ease 0.3s;
  }
  &[data-inview-delay='0.4'] {
    transition: opacity 1.4s ease 0.4s;
  }
  &[data-inview-delay='0.5'] {
    transition: opacity 1.4s ease 0.5s;
  }
  &[data-inview-delay='0.6'] {
    transition: opacity 1.4s ease 0.6s;
  }
  &[data-inview-delay='0.9'] {
    transition: opacity 1.4s ease 0.9s;
  }
  &.is-inview {
    opacity: 1 !important;
  }
}

*[data-image-reveal='true'] {
  position: relative;
  overflow: hidden;
  img {
    will-change: transform;
    transform-origin: center;
    @include pos100(absolute);
    object-fit: cover;
    transform: scale(1.2);
    opacity: 0;
    transition: transform 2s $ease-out-quart, opacity 0.3s ease;
  }
  &[data-inview-delay='0.1'] {
    transition: transform 2s $ease-out-quart 0.1s, opacity 0.3s ease;
  }
  &[data-inview-delay='0.2'] {
    transition: transform 2s $ease-out-quart 0.2s, opacity 0.3s ease;
  }
  &[data-inview-delay='0.3'] {
    transition: transform 2s $ease-out-quart 0.3s, opacity 0.3s ease;
  }
  &[data-inview-delay='0.4'] {
    transition: transform 2s $ease-out-quart 0.4s, opacity 0.3s ease;
  }
  &[data-inview-delay='0.5'] {
    transition: transform 2s $ease-out-quart 0.5s, opacity 0.3s ease;
  }
  &[data-inview-delay='0.6'] {
    transition: transform 2s $ease-out-quart 0.6s, opacity 0.3s ease;
  }
  &[data-inview-delay='0.9'] {
    transition: transform 2s $ease-out-quart 0.9s, opacity 0.3s ease;
  }

  &.is-inview {
    transform: scale(1);
    img {
      opacity: 1;
      transform: scale(1);
    }
  }
}

*[data-image-zoom-reveal='true'] {
  position: relative;
  overflow: hidden;
  figure {
    @include pos100(absolute);
    overflow: hidden;
    width: 80%;
    height: 80%;
    @include center(xy);
    transition: all 1.6s $ease-out-quart;
  }
  img {
    will-change: transform;
    transform-origin: center;
    @include pos100(absolute);
    object-fit: cover;
    transform: scale(1.6);
    transition: transform 2s $ease-out-quart;
  }
  &.is-inview {
    transform: scale(1);
    figure {
      width: 100%;
      height: 100%;
    }
    img {
      opacity: 1;
      transform: scale(1);
    }
  }
}

*[data-inview-translate='true'] {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 0.8s $ease-out-quart, opacity 1s ease;
  @include media('>=medium') {
    transform: translateY(60px);
  }
  @include media('>=large') {
    transform: translateY(100px);
  }
  &[data-inview-delay='0.1'] {
    transition: transform 0.8s $ease-out-quart 0.1s, opacity 1s ease 0.1s;
  }
  &[data-inview-delay='0.2'] {
    transition: transform 0.8s $ease-out-quart 0.2s, opacity 1s ease 0.2s;
  }
  &[data-inview-delay='0.3'] {
    transition: transform 0.8s $ease-out-quart 0.3s, opacity 1s ease 0.3s;
  }
  &[data-inview-delay='0.4'] {
    transition: transform 0.8s $ease-out-quart 0.4s, opacity 1s ease 0.4s;
  }
  &[data-inview-delay='0.5'] {
    transition: transform 0.8s $ease-out-quart 0.5s, opacity 1s ease 0.5s;
  }
  &[data-inview-delay='0.6'] {
    transition: transform 0.8s $ease-out-quart 0.6s, opacity 1s ease 0.6s;
  }
  &[data-inview-delay='0.7'] {
    transition: transform 0.8s $ease-out-quart 0.7s, opacity 1s ease 0.7s;
  }
  &[data-inview-delay='0.8'] {
    transition: transform 0.8s $ease-out-quart 0.8s, opacity 1s ease 0.8s;
  }
  &[data-inview-delay='0.9'] {
    transition: transform 0.8s $ease-out-quart 0.9s, opacity 1s ease 0.9s;
  }

  &.is-inview {
    opacity: 1 !important;
    transform: translateY(0);
  }
}

*[data-text-reveal='true'] {
  .textLine {
    position: relative;
    overflow: hidden;
    display: flex;
  }
  .textLine-inner {
    display: block;
    // width: 100%;
    position: relative;
    // white-space: nowrap;
    will-change: transform;
    transform: translateY(150%) rotate(4deg);
    transition: transform 1.3s cubic-bezier(0.2, 0, 0.1, 1);
  }
  &.is-inview {
    .textLine-inner,
    .text-component__label span {
      transform: translateY(0) rotate(0);
      transition: transform 1.3s cubic-bezier(0.2, 0, 0.1, 1);
    }
  }
}

.text-component {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  * {
    white-space: normal;
  }
  &.text-component--center {
    text-align: center;
    align-items: center;
    .textLine {
      justify-content: center;
    }
  }
  &.text-component--light {
    .text-component__title {
      color: $white;
    }
    .text-component__teaser {
      color: $white;
    }
  }
  &.text-component--decoration {
    &:after {
      content: '';
      background-color: $red;
      width: 0;
      position: absolute;
      top: 110%;
      right: 0;
      height: px(6);
      transition: width 1.6s ease 1.2s;
      @include media('>=large') {
        height: px(10);
      }
    }
    &.is-inview,
    .is-inview & {
      &:after {
        width: 30%;
      }
    }
  }
}

.text-component__label {
  color: $red;
  font-family: var(--font-tertiary);
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: var(--space-xs);
  letter-spacing: 2px;
  overflow: hidden;
  // font-size: clamp(2rem, 0.9157rem + 4.8193vw, 4rem);
  @include media('>=large') {
    font-size: clamp(1rem, 0.5646rem + 0.6803vw, 1.5rem);
  }
  span {
    display: block;
    width: 100%;
    position: relative;
    white-space: nowrap;
    will-change: transform;
    transform: translateY(150%) rotate(4deg);
    transition: transform 1.3s cubic-bezier(0.2, 0, 0.1, 1);
  }
}
.text-component__title {
  color: $black;
  font-family: var(--font-secondary);
  font-size: var(--text-xl);
  font-weight: 880;
  line-height: 1.2;
  margin-bottom: var(--space-xxxs);
  font-size: 2rem;
  font-size: clamp(2rem, 1.1867rem + 3.6145vw, 3.5rem);
  @include media('>=large') {
    font-size: 3rem;
    font-size: clamp(3rem, 1.2585rem + 2.7211vw, 5rem);
  }
  span {
    padding: 0.1em 0;
  }
}
.text-component__teaser {
  color: $black;
  line-height: 1.6;
  font-size: 1rem;
  font-size: clamp(1rem, 0.7831rem + 0.9639vw, 1.4rem);
  @include media('>=large') {
    font-size: 1.2rem;
    font-size: clamp(1.2rem, 0.5034rem + 1.0884vw, 2rem);
  }
}

// button in text comp
.text-component {
  .Button {
    transform: translateY(20%);
    opacity: 0;
    transition: transform 0.6s ease 0.3s, opacity 0.3s ease 0.3s;
    will-change: transform;
  }
  &.text-component--center {
    .Button {
      right: px(10);
    }
  }
  &.is-inview .Button {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-intro-sectionCircle {
  opacity: 0;
  position: relative;
  width: 100%;
  height: 20vmax;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: var(--space-sm);
  will-change: transform;
  transform: translateY(-150%);
  @include media('>=msmall') {
    height: 16vmax;
  }
  @include media('>=medium') {
    height: 18vmax;
  }
  @include media('>=large') {
    height: 10vmax;
  }
  svg {
    height: auto;
    width: 50%;
    @include media('>=medium') {
      width: 42%;
    }
    @include media('>=large') {
      width: 25%;
    }
  }
  span {
    position: absolute;
    @include center(x);
    bottom: 1.7vmax;
    color: $red;
    font-family: var(--font-tertiary);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.1rem;
    font-size: clamp(0.8rem, 0.4747rem + 1.4458vw, 1.4rem);
    @include media('>=large') {
      font-size: 1.2rem;
      font-size: clamp(1rem, 0.5646rem + 0.6803vw, 1.5rem);
    }
  }
}

.page-intro-title {
  @include flex-center;
  flex-direction: column;
  text-align: center;
  margin-bottom: var(--space-sm);
  padding: 0 1vw;

  > div {
    position: relative;
    display: block;
    overflow: hidden;
  }
  span {
    opacity: 0;
    display: block;
    position: relative;
    @include clean;
    font-family: var(--font-secondary);
    color: $dark;
    font-weight: 850;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: px(1);
    transform-origin: 0% 50%;
    will-change: transform;
    transform: translateY(150%);
    font-size: 3rem;
    font-size: clamp(2.4rem, 0.9904rem + 6.2651vw, 5rem);
    @include media('>=large') {
      letter-spacing: px(2);
      font-size: 5rem;
      font-size: clamp(4rem, 0.517rem + 5.4422vw, 8rem);
    }
  }
}

.page-intro-label {
  @include flex-center;
  overflow: hidden;
  margin-bottom: var(--space-md);
  @include media('>=hd') {
    margin-bottom: var(--space-lg);
  }
  > span {
    opacity: 0;
    will-change: transform;
    transform: translateY(150%);
    padding: px(5) px(10);
    font-family: var(--font-secondary);
    color: $white;
    background-color: $red;
    font-style: italic;
    letter-spacing: px(1);
    font-size: 1rem;
    font-size: clamp(0.8rem, 0.5831rem + 0.9639vw, 1.2rem);
    font-weight: 650;
    @include media('>=large') {
      font-size: 1.2rem;
      font-size: clamp(1rem, 0.5646rem + 0.6803vw, 1.5rem);
    }
  }
}

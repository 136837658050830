// ====================================================================
// Base palette
//
// Grayscale and brand colors for use across the site.
// ====================================================================
$white: #fff;
$black: #04030f;
$light: #e6e0e0;
$red: #d63e35;
$dark: #221f20;

$facebook: #4267b2;
$youtube: #ff0000;
$instagram: #e1306c;
$twitter: #1da1f2;

$grey-10: #141414;
$grey-9: #232323;
$grey-8: #353535;
$grey-7: #474747;
$grey-6: #757575;
$grey-5: #a3a3a3;
$grey-4: #bfbfbf;
$grey-3: #dadada;
$grey-2: #ededed;
$grey-1: #f6f6f6;

// ====================================================================
// General
//
// Global color variables, used usually in sections
// Global variables used across the web app
// ====================================================================
$element-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);
$generic-fonts: 'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;

// ====================================================================
// List of z-index element
// ====================================================================
$elements: main, footer, vsscrollbar, canvas, agegate, header, cookie, grain;

// ====================================================================
// easings
// ====================================================================
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
// EASE OUT
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
// EASE IN OUT
$ease-in-out: cubic-bezier(0.17, 0.67, 0.83, 0.67);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

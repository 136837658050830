@import 'shared';

.Switcher {
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  justify-content: center;
  @include media('>=large') {
    justify-content: flex-start;
  }
}

.SwitcherVisible {
  opacity: 1;
  transition: opacity 0.7s ease 0.4s, transform 0.7s ease 0.4s;
  transform: translateY(0);
}

.button,
.disabled {
  line-height: 1;
  display: inline-flex;
  @include clean;
  position: relative;
  color: var(--menu-color);
  font-family: var(--font-secondary);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.12em;
  font-size: 1.2rem;
  @include media('>=large') {
    font-size: var(--text-md);
    font-size: clamp(var(--text-sm), 2vw, var(--text-lg));
  }
}

.button {
  margin-right: 1vmax;
  font-weight: 650;
  &:hover {
    color: $red;
  }
}
.disabled {
  font-weight: 450;
  opacity: 0.4;
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  z-index: 0;
  // background-color: $dark;
}

html {
  box-sizing: border-box;
  font-family: var(--font-primary);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // font-size: pxrem(16);
  background-color: $white;
  font-family: var(--font-primary);
  @include media('>=large') {
    overflow: hidden;
    &.changePage {
      cursor: wait;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    overflow-x: hidden;
    overflow-y: visible;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: $red;
}

.only-aria-visible {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px); /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

a {
  color: inherit;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd {
  margin: 0;
  padding: 0;
}

figure {
  @include clean;
}

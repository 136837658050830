@import 'shared';

.Cursor {
  opacity: 0;
  position: fixed;
  width: px(8);
  height: px(8);
  pointer-events: none;
  z-index: 9999;
  // left: -100px;
  // top: -100px;
  transition: opacity 0.3s ease;

  display: none;
  // mix-blend-mode: hard-light;

  @include media('>=large') {
    display: block;
  }
  .defaultCursor {
    display: block;
    position: absolute;
    @include fill;
    top: -50%;
    left: -50%;
    // transform: translate(-50%, -50%);
    border-radius: 100%;
    transform-origin: center center;
    // border: 1px solid rgba($red, 0.8);
    background-color: transparent;
    background-color: $red;
  }
  // svg {
  //   display: block;
  //   position: absolute;
  //   width: px(70);
  //   height: px(70);
  //   transition: opacity 0.6s ease 0.5s;
  //   transform: translate(-50%, -50%);
  //   path {
  //     fill: $red;
  //   }
  // }
}

.CursorHidden {
  opacity: 0 !important;
  transition: opacity 0s;
}
.CursorLocked {
  width: px(40);
  height: px(40);
  transition: width 0.2s ease, height 0.2s ease, opacity 0.3s ease;

  // mix-blend-mode: hard-light;
  .defaultCursor {
    // filter: blur(20px);
    border: 2px solid $red;
    background-color: transparent;
  }
}
// .CursorPointer {
//   width: px(16);
//   height: px(16);
//   .defaultCursor {
//     border: 0;
//     background-color: rgba($red, 0.13);
//     // filter: blur(12px);
//   }
// }
.CursorHover {
  width: px(16);
  height: px(16);
  mix-blend-mode: hard-light;

  .defaultCursor {
    border: 0;
    background-color: rgba($red, 0.6);
    transform-origin: center;
  }
}
// .ArrowRight {
//   width: 50px;
//   height: 50px;
//   .defaultCursor {
//     display: none;
//   }
// }
// .ArrowLeft {
//   width: 50px;
//   height: 50px;
//   .defaultCursor {
//     display: none;
//   }
// }

// .Play {
//   .defaultCursor {
//     display: none;
//   }
//   svg {
//     width: px(50);
//     height: px(50);
//   }
// }
// .Intro {
//   width: px(54);
//   height: px(54);
//   .defaultCursor {
//     border-radius: 100%;
//     transform-origin: center center;
//     background-color: $red;
//   }
//   .introText {
//     display: block;
//     position: absolute;
//     text-align: center;
//     @include fill;
//     top: -5%;
//     left: -50%;
//     text-transform: uppercase;
//     color: $white;
//     font-weight: 500;
//     font-size: var(--text-xs);
//     letter-spacing: 0.07em;
//   }
// }

// .Zoom {
//   .defaultCursor {
//     display: none;
//   }
//   transform: rotate(-12deg);

//   svg {
//     width: px(34);
//     height: px(34);
//   }
// }
// .CursorSpinner {
//   .defaultCursor {
//     display: none;
//   }
//   .spinnerContainer {
//     animation: icon-spin 1s infinite linear;
//     transform-origin: top left;
//     display: block;
//     width: px(34);
//     height: px(34);

//     svg {
//       @include fill;
//     }
//   }
// }

@use 'sass:math';
// Use two pixel values to convert line-height into a unitless number
// @example scss Input
//   p {
//     font-size: 16px;
//     line-height: line-height(16, 30);
//   }
@function line-height($font-size, $line-height) {
  @if $line-height==normal {
    @return normal;
  }
  @return $line-height / $font-size;
}

// Use two pixel values to convert letter-spacing into an em value
// @example scss Input
//   p {
//     font-size: 16px;
//     // letter-spacing: -0.1px;
//     letter-spacing: letter-spacing(16, -0.1);
//   }
@function letter-spacing($font-size, $letter-spacing) {
  @if $letter-spacing==normal {
    @return normal;
  }
  @return #{$letter-spacing / $font-size}em;
}

// Define font-size, line-height and letter-spacing in function
// @example scss Input
//   p {
//     // font-size: 16px;
//     // letter-spacing: -0.1px;
//     // line-height: 1.5; // This is line-height 24px and font-size: 16px
//     @include font-size(16, 24, -0.1)
//   }
@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: px($font-size);
  line-height: line-height($font-size, $line-height);

  @if $letter-spacing !=0 {
    letter-spacing: letter-spacing($font-size, $letter-spacing);
  }
}

// Define font-clamp, Linearly Scale font-size with CSS clamp() Based on the Viewport
// Personal scss mixin conversion of generator by csstricks
// https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
// @example scss Input
//   p {
//     @include font-fluid(40, 80, 1024, 2200);
//   }
@mixin font-fluid($min-fs, $max-fs, $minWidthPx, $maxWidthPx) {
  $minWidth: math.div($minWidthPx, 16);
  $maxWidth: math.div($maxWidthPx, 16);

  $min-fs-strip: $min-fs * 0.1;
  $max-fs-strip: $max-fs * 0.1;

  $min-fs-rem: $min-fs * 0.1rem;
  $max-fs-rem: $max-fs * 0.1rem;

  $slope: math.div(($max-fs-strip - $min-fs-strip), ($maxWidth - $minWidth));
  $yAxisIntersection: (-$minWidth * $slope) + $min-fs-strip;

  $yAxisIntersection-rem: $yAxisIntersection * 1rem;
  $slope-vw: $slope * 100vw;

  font-size: clamp($min-fs-rem, (#{$yAxisIntersection-rem} + #{$slope-vw}), $max-fs-rem);
}

/// Fluid Typography
/// https://css-tricks.com/snippets/css/fluid-typography/
///
/// html {
///   @include fluid-type($min_font, $max_font, $min_width, $max_width);
/// }

$min-vw-default: map-get($breakpoints, large);
$max-vw-default: map-get($breakpoints, hd);

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-font-size, $max-font-size, $min-vw: $min-vw-default, $max-vw: $max-vw-default) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            (math.div((100vw - #{$min-vw}), #{strip-unit($max-vw - $min-vw)}))
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/// Improve animation performance for containers
/// @example scss Input
///   .card {
///     @include gpu();
///   }
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

/// Set exactly the same width and height of a component, avoid repeat the same dimension
/// @parameter {Measure unit} $width - Specify width of the box
/// @parameter {Measure unit} $height [$width] - Specify height of the box, the default value is the width
/// @example scss Input
///   .card {
///     @include box(3.2rem);
///   }
///   .card-another {
///     @include box(3.2rem, 2rem);
///   }
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

/// Convert the pixels to rem for in a specific resolution, usually 1440px wide
/// @example scss Input
///   section {
///     height: px(200);
///   }
@function px($px) {
  @return $px * 0.1rem;
}

/// Convert the pixels to rem for in a specific resolution, usually 1440px wide
/// @example scss Input
///   section {
///     height: pxrem(200);
///   }
$baseFontSize: 16;
@function pxrem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1rem;
}

/// Set top and left to 0
/// @example scss Input
///   element {
///     @include pos0;
///   }
@mixin pos0 {
  top: 0;
  left: 0;
}

/// Set full size of box and position
/// @parameter {string} $pos - one of relative, absolute, fixed. relative by default
/// @example scss Input
///   element {
///     @include pos100(absolute);
///   }
@mixin pos100($pos: relative) {
  position: $pos;
  @include pos0;
  @include box(100%);
}

/// Set display flex and center children horizontally and vertically
/// @example scss Input
///   element {
///     @include flex-center;
///   }
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/// Set display flex and center children vertically
/// @example scss Input
///   element {
///     @include flex-center-vert;
///   }
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

/// Set display flex and center children horizontally
/// @example scss Input
///   element {
///     @include flex-center-horiz;
///   }
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/// Center its element (horizontally and vertically) by using transform
/// @example scss Input
///   element {
///     @include transform-center;
///   }
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/// Set vertical center of its element
/// @example scss Input
///   element {
///     @include transform-center-vert;
///   }
@mixin transform-center-vert {
  top: 50%;
  transform: translateY(-50%);
}

/// Set horizontal center of its element
/// @example scss Input
///   element {
///     @include transform-center-horiz;
///   }
@mixin transform-center-horiz {
  left: 50%;
  transform: translateX(-50%);
}

/// Set z-index value in order of $element variable
/// @example scss Input
///   element {
///     @include z-index(preloader);
///   }
@mixin z-index($id) {
  z-index: index($elements, $id);
}

/// Set breakpoint via media queries
/// @example scss Input
///   element {
///     [...phone styles....]
///     @include breakpoint(tablet) {
///        [...tablet+desktop styles...]
///     }
///   }
@mixin breakpoint($size) {
  @media (min-width: #{map-get($layout, $size) + px}) {
    @content;
  }
}

/// Reset button's look
/// @example scss Input
///   button {
///     @include reset-button;
///   }
@mixin reset-button {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  color: inherit;
}

// Custom Easing Mixin
// Example:
// @include customEase(0.25, 0.25, 0.75, 0.75);
@mixin customEase($timing...) {
  transition-timing-function: cubic-bezier($timing);
}

// clean
@mixin clean {
  margin: 0;
  padding: 0;
}

// clean
@mixin fill {
  width: 100%;
  height: 100%;
}

// retina images on background
@mixin retinaImage($file, $type, $width, $height, $horizontal_pos: left, $vertical_pos: top) {
  background-image: url($file + '.' + $type);
  background-position: $vertical_pos $horizontal_pos;
  background-repeat: no-repeat;
  -webkit-background-size: $width $height;
  -moz-background-size: $width $height;
  -o-background-size: $width $height;
  background-size: $width $height;
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    & {
      background-image: url($file + '@2x.' + $type);
      -webkit-background-size: $width $height;
      -moz-background-size: $width $height;
      -o-background-size: $width $height;
      background-size: $width $height;
      background-position: $vertical_pos $horizontal_pos;
      background-repeat: no-repeat;
    }
  }
}

// crop top space on text elements - caused by line height
@mixin lhCrop($line-height, $capital-letter: 1) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
}

// CSS triangle
@mixin triangle($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if ($direction == left) {
    border-right-color: $color;
  } @else if($direction == right) {
    border-left-color: $color;
  } @else if($direction == down) {
    border-top-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

// clearfix
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// center with absolute & transform
@mixin center($xy: xy, $centerX: -50%, $centerY: -50%) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translate($centerX, $centerY);
  } // xy
  @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX($centerX);
  } // x
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY($centerY);
  } // y
} // @mixin center

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import 'shared';

.BurgerWrapper {
  width: px(60);
  height: px(40);
  @include flex-center;
  background-color: transparent;
  position: relative;
  z-index: 2000000;
  cursor: pointer;

  @include media('>=medium') {
    height: px(50);
  }

  @include media('>=large') {
    width: 100%;
    height: px(60);
  }

  &:after {
    z-index: 2;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: var(--header-color);
    transition: background-color 0.8s ease;

    @include media('>=large') {
      width: 100%;
      height: 1px;
    }
  }
}

.Burger {
  // top: px(2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 1.8rem;
  height: 1rem;

  @include media('>=medium') {
    width: 2.2rem;
    height: 1.4rem;
  }

  .Bar {
    width: 100%;
    background-color: var(--header-color);
    position: relative;
    height: 2px;
    transition: transform 0.3s ease, background-color 1.4s ease;
    transform-origin: top left;

    @include media('>=medium') {
      height: 3px;
    }

    &:nth-child(2) {
      width: 80%;
      left: 20%;
    }
  }
}

.BurgerClose {
  left: px(2);
  width: 1.6rem;
  height: 1.3rem;

  @include media('>=medium') {
    bottom: auto;
    width: 2.1rem;
    height: 1.6rem;
  }

  .Bar {
    &:nth-child(1) {
      transform: translateX(1px) rotate(45deg);
    }

    &:nth-child(2) {
      transform: scaleX(0);
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.Menu {
  --menu-white-color: #fff;
  --menu-black-color: #04030f;
  --menu-dark-color: #221f20;
  --menu-bg-color: var(--menu-white-color);
  --menu-color: var(--menu-black-color);
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  // background-color: $white;
  transition: visibility 0s 0.7s, opacity 0.3s ease 0.4s;
  @include flex-center;

  @include media('>=large') {
    overflow: hidden;
  }

  &__inner {
    display: flex;
    text-align: left;
    position: relative;
    flex-direction: column;
    top: -5%;

    @include media('>=large') {
      top: auto;
      flex-direction: row;
      width: 60%;
    }
  }

  &:not(.MenuOpen) {
    .nav:after {
      animation: animate-bar-out 0.3s;
    }
  }
}

.MenuOpen {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 0.2s ease 0s, visibility 0s ease 0s;

  .nav:after {
    top: 0;
    animation: animate-bar-in 1s forwards;
  }
}

.Menu__bg {
  @include pos100(absolute);
  background-color: transparent;
  background-color: var(--menu-bg-color);
}

.Menu__bgPlantTop {
  display: none;
  position: absolute;
  top: -10vh;
  @include center(x);

  @include media('>=large') {
    display: block;
    transform: none;
    right: auto;
    left: 2%;
    bottom: auto;
    top: -24%;
    width: px(170);
    width: clamp(px(120), 35%, px(340));
  }

  @include media('>=xlarge') {
    top: -17%;
  }

  svg {
    width: 100%;
    height: auto;
    position: relative;
    transform: rotate(180deg) scaleX(-1);
    opacity: 0.3;
    top: -15%;

    @include media('>=large') {
      top: 20%;
      right: -20%;
    }

    path {
      fill: $dark;
    }
  }
}

.Menu__bgPlant {
  position: absolute;
  top: 90vh;
  @include center(x);

  @include media('>=large') {
    transform: none;
    left: auto;
    right: 0;
    top: auto;
    bottom: -5%;
    width: px(170);
    width: clamp(px(120), 35%, px(340));
  }

  svg {
    width: 100%;
    height: auto;
    position: relative;
    transform: rotate(-60deg);
    opacity: 0.3;
    top: -10%;

    @include media('>=large') {
      top: 20%;
      right: -20%;
    }

    path {
      fill: $dark;
    }
  }
}

.nav {
  @include flex-center;
  position: relative;
  margin-bottom: var(--space-lg);
  padding-top: 5vmax;

  @include media('>=large') {
    justify-content: flex-start;
    margin-bottom: 0;
    padding-top: 0;
    width: 70%;
  }

  @keyframes animate-bar-in {
    0% {
      height: 0;
    }

    100% {
      height: 100%;
    }
  }

  @keyframes animate-bar-out {
    0% {
      height: 100%;
    }

    100% {
      height: 0;
    }
  }

  &:after {
    width: 1px;
    height: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: var(--menu-color);
    animation-timing-function: $ease-out-quad;

    @include media('>=large') {
      content: '';
    }
  }

  ul {
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
    align-content: center;
    justify-content: center;

    @include media('>=large') {
      text-align: left;
    }

    li {
      margin-top: 3vmax;
      grid-column: 2;
      overflow: hidden;

      @include media('>=large') {
        margin-top: 2.1vmax;
      }

      &:first-child {
        margin-top: 0;
      }

      a {
        position: relative;
        font-family: var(--font-secondary);
        text-transform: uppercase;
        color: var(--menu-color);
        letter-spacing: 2px;
        font-size: var(--text-md);
        line-height: 1;
        font-weight: bold;
        font-weight: 780;
        text-decoration: none;
        transition: color 0.3s ease, letter-spacing 0.6s ease;
        display: inline-block;
        transform-origin: 0% 50%;
        will-change: transform;
        transform: translateY(150%);
        font-size: 2rem;
        padding-bottom: 0.11em;
        font-size: clamp(1.7rem, 0.8867rem + 3.6145vw, 3.2rem);

        @include media('>=large') {
          font-size: 3.5rem;
          font-size: clamp(2.8rem, 0.8844rem + 2.9932vw, 5rem);

          &:hover {
            color: $red;
            letter-spacing: 4px;
          }
        }
      }
    }

    .NavLinkActive {
      a {
        color: $red;
        font-weight: 700;
        font-style: italic;
      }
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  @include media('>=large') {
    text-align: left;
    padding: 0 0 6vmax 5vmax;
  }
}

.sidebar__link {
  overflow: hidden;
  line-height: 1;
  margin-bottom: var(--space-lg);

  @include media('>=large') {
    margin-bottom: 0;
  }

  >a {
    position: relative;
    font-family: var(--font-secondary);
    color: var(--menu-color);
    letter-spacing: 1px;
    font-size: var(--text-md);
    line-height: 1.1;
    font-weight: bold;
    font-weight: 550;
    text-decoration: none;
    transition: color 0.3s ease, letter-spacing 0.6s ease;
    display: inline-block;
    transform-origin: 0% 50%;
    will-change: transform;
    transform: translateY(150%);
    font-size: 1.5rem;
    font-size: clamp(1.2rem, 0.6578rem + 2.4096vw, 2.2rem);

    @include media('>=large') {
      font-size: 2.7rem;
      font-size: clamp(2.2rem, 1.3293rem + 1.3605vw, 3.2rem);
    }

    &:hover {
      color: $red;
      letter-spacing: 2px;
    }
  }
}

.sidebar__linkActive {
  >a {
    color: $red;
    font-style: italic;
  }
}

.social {
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: var(--space-md);

  @include media('>=large') {
    margin-bottom: 0;
    justify-content: flex-start;
  }

  a {
    position: relative;
    transform: translateY(150%);

    &:first-child {
      margin-right: px(15);
    }

    &:hover {
      svg {
        fill: $red;
      }
    }
  }

  svg {
    padding: 1px;
    transition: fill 0.3s ease;
    margin-bottom: px(5);
    fill: var(--menu-color);
    opacity: 0.9;
    width: px(20);
    height: px(20);
    width: clamp(px(15), 3vw, px(25));
    height: clamp(px(15), 3vw, px(25));
  }
}
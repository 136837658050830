@import 'shared';

.Header {
  --header-white-color: #fff;
  --header-black-color: #04030f;
  --header-color: var(--header-white-color);
  display: flex;
  align-content: center;
  @include z-index(header);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: px(40);
  background-color: transparent;
  transition: background-color 0.3s ease;
  @include media('>=medium') {
    height: px(50);
  }
  @include media('>=large') {
    flex-direction: column;
    flex-wrap: wrap;
    height: 100vh;
    width: px(60);
  }
  &:after {
    z-index: 2;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: var(--header-color);
    transition: background-color 0.8s ease;
    @include media('>=large') {
      width: 1px;
      height: 100%;
    }
  }

  .brand {
    width: 100%;
    flex-grow: 1;
    position: relative;
    z-index: 2;
    @include flex-center;
    h1 {
      @include clean;
      line-height: 100%;
      > span {
        line-height: 100%;
        @extend %hide-element;
      }
    }
    .logo {
      height: auto;
      width: px(80);
      @include media('>=medium') {
        width: px(90);
      }
      @include media('>=large') {
        transform: rotate(90deg);
        width: px(80);
      }
      path {
        transition: fill 1.4s ease;
        fill: var(--header-color);
      }
    }
  }
  .plant {
    width: px(60);
    height: px(40);
    @include flex-center;
    background-color: transparent;
    position: relative;
    z-index: 2;
    @include media('>=medium') {
      height: px(50);
    }
    @include media('>=large') {
      width: 100%;
      height: px(60);
    }
    &:after {
      z-index: 2;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: var(--header-color);
      transition: background-color 0.8s ease;
      @include media('>=large') {
        width: 100%;
        height: 1px;
      }
    }
    svg {
      width: px(30);
      height: px(30);
      path {
        transition: stroke 1.4s ease;
        stroke: var(--header-color);
        stroke-width: 1px;
      }
    }
  }
}
